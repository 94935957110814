<template>

  <a-spin class="loadingClass" v-if="dataValue.loading === true" tip="正在加载数据 ..."></a-spin>

  <div class="divView" v-if="dataValue.loading === false">
    <div style="float: left;margin: 5px 0 5px 0;">我的系统编号：{{ dataValue.mySet.employeeId  }}</div>
    <a-input class="inp" placeholder="姓名" v-model:value="dataValue.mySet.employeeName"/>
    <a-input class="inp" placeholder="手机号" v-model:value="dataValue.mySet.mobile"/>
    <div style="float: left;margin: 5px 0 5px 0;">我的公司邮件：</div>
    <a-input class="inp" style="width:100px;float: left;clear: both;" placeholder="邮箱" v-model:value="dataValue.mySet.email"/>
    <label style="float: left;margin: 3px 0 0 5px;">@chipslinking.com</label>
    <a-input-password class="inp" placeholder="邮箱密码" v-model:value="dataValue.mySet.emailPass"/>
    <div style="float: left;margin: 5px 0 5px 0;">我的企业微信：</div>
    <a-input class="inp" placeholder="企业微信登录名" v-model:value="dataValue.mySet.qyWeiXin"/>
    <a-button class="bun" type="primary" @click="allEvent.set">保存</a-button>
  </div>
</template>

<script>
import {reactive} from "vue";
import proxy from "@/utils/axios";
import {message} from "ant-design-vue";

export default {
  name : "my_set",
  emits : [ 'goClose' ],
  setup(props, { emit }) {

    let dataValue = reactive({

      loading : false,

      mySet : {
        employeeId : 0,
        employeeName : "",
        mobile : "",
        email : "",
        emailPass : "",
        qyWeiXin : ""
      }
    });

    let allEvent = {

      getHttp : ()=>{

        dataValue.loading = true;

        proxy.newApi('/organization/employee/get', {} ).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.mySet.employeeId   = res["data"]["employeeId"];
            dataValue.mySet.employeeName = res["data"]["employeeName"];
            dataValue.mySet.mobile       = res["data"]["mobile"];
            dataValue.mySet.email        = res["data"]["email"];
            dataValue.mySet.emailPass    = res["data"]["emailPass"];
            dataValue.mySet.qyWeiXin     = res["data"]["qyWeiXin"];
          }

          dataValue.loading = false;
        });

      },

      set : ()=>{
        dataValue.loading = true;

        proxy.newApi('/organization/employee/add', dataValue.mySet ).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            emit( 'goClose' );
          }

          dataValue.loading = false;
        });
      },

      init : ()=>{
        allEvent.getHttp();
      }
    }

    allEvent.init();

    return {
      dataValue,
      allEvent
    }
  }
}
</script>

<style scoped>
.loadingClass { width: 100%; text-align: center; padding-top: 100px; }
.divView{ text-align: center; }
.inp{ margin-bottom: 10px; }
.bun{ margin-top: 20px; }
</style>