<template>
  <headerMenu v-if="!dataValue.haveNoneTitle" :menu_key="dataValue.menuSelectKey"/>
  <iframe class="noneFrame" :src="dataValue.jwtAdmin"/>
  <div :class="dataValue.haveNoneTitle ? 'frameListB' : 'frameListA'" v-if="dataValue.showFrameList">
    <router-view @setMenuKey="setMenuKey"/>
  </div>
</template>

<script>

import headerMenu from '@/components/menu'
import router from "@/router";
import store from '@/store'
import Cookies from 'js-cookie'
import {reactive} from "vue";


export default {
  name : "framework",
  components : { headerMenu },
  setup() {

    let dataValue = reactive({
      menuSelectKey : "",
      showFrameList : false,
      haveNoneTitle : false,
      jwtAdmin : "",
    });


    const url = window.location.href;
    dataValue.haveNoneTitle = url.includes('?none');


    // 判断cookie里面有jwtAdmin，就赋值给store.state.jwt

    let jwt = store.state.jwt;

    if( jwt === "" ){
      let jwtAdmin = Cookies.get('jwtAdmin')
      if (jwtAdmin) {
        jwt = jwtAdmin
        store.commit("setJwt", jwtAdmin);
      }
    }

    dataValue.jwtAdmin = process.env.VUE_APP_DAILIAO_WEB_URL +"/admin/?jwtAdmin=" + jwt;

    // 如果发现没有jwt文件，直接条到登陆页
    if (jwt === "" || store.state.edition !== 20221209 ) {
      router.push("/login")
    } else {
      dataValue.showFrameList = true
    }

    let setMenuKey = (key) => {
      // message.info(key)
      dataValue.menuSelectKey = key;
    }

    return {
      dataValue,
      setMenuKey
    }
  }
}
</script>

<style>
.noneFrame { display: none }

.frameListA { overflow: auto; background-color: #f9f9f9; position: fixed; width: 100%; border-top: 1px solid #ffffff;
  bottom:0; top:66px; }
.frameListA iframe { height: calc(100% - 8px); }

.frameListB { overflow: auto; background-color: #f9f9f9; position: fixed; width: 100%; border-top: 1px solid #ffffff;
  bottom:0; top:0; }
.frameListB iframe { height: calc(100% - 8px); }

</style>