<template>
  <a-layout class="topHeader">
    <!--    {{dataValue.functionIds}}-->
    <div class="logo"><img src="@/assets/public/logo2.jpg" alt="闲芯"></div>
    <a-menu theme="light" class="topMenu" mode="horizontal" v-model:selected-keys="dataValue.menuSelectKey"
            style="width:600px;background-color: transparent;position: fixed; left: 100px; top:10px;"
            triggerSubMenuAction="click">

      <a-sub-menu key="dj" v-if="allEvent.tF(1)">
        <template #title>
          <insert-row-above-outlined/>
          单据
        </template>
        <a-menu-item key="dj.gckcbj" v-if="allEvent.tF(10)">
          <router-link to="/order/stock.one">工厂库存报价</router-link>
        </a-menu-item>
        <a-menu-item key="dj.scsp" v-if="allEvent.tF(10)">
          <router-link to="/order/stock.list.no.examine">上传审批</router-link>
        </a-menu-item>
<!--        <a-menu-item key="dj.dbjkc" v-if="allEvent.tF(10)">-->
<!--          <router-link to="/order/stock.list.batch.all">待报价工厂库存</router-link>-->
<!--        </a-menu-item>-->

        <a-menu-divider v-if="allEvent.tF(11)"/>


        <a-menu-item key="dj.gcxhbj" v-if="allEvent.tF(11)">
          <router-link to="/order/factory.spot">工厂销售报价</router-link>
        </a-menu-item>
        <a-menu-item key="dj.gcxhmx" v-if="allEvent.tF(11)">
          <router-link to="/order/detail/factory.spot">工厂销售记录明细</router-link>
        </a-menu-item>


        <a-menu-divider v-if="allEvent.tF(12)"/>

        <a-menu-item key="dj.mysbj" v-if="allEvent.tF(12)">
          <router-link to="/order/merchant">贸易商报价</router-link>
        </a-menu-item>
        <a-menu-item key="dj.mysbjmx" v-if="allEvent.tF(12)">
          <router-link to="/order/detail/merchant">贸易商记录明细</router-link>
        </a-menu-item>


      </a-sub-menu>

      <a-sub-menu key="top6" v-if="allEvent.tF(2,23,63,66,67)">
        <template #title>
          <database-outlined/>
          运营
        </template>

        <a-menu-item key="yy.ppwdbmlb" v-if="allEvent.tF(66)">
          <router-link to="/uplater/up2.bom.list">匹配我的BOM型号</router-link>
        </a-menu-item>

<!--        <a-menu-item key="yy.yscbomtg" v-if="allEvent.tF(66)">-->
<!--          <router-link to="/uplater/up.bom.list">已上传BOM匹配公司</router-link>-->
<!--        </a-menu-item>-->
<!--        <a-menu-item key="yy.ppgstg" v-if="allEvent.tF(66)">-->
<!--          <router-link to="/uplater/up.company.list">匹配公司推广</router-link>-->
<!--        </a-menu-item>-->
<!--        <a-menu-item key="kc.ytgjl" v-if="allEvent.tF(66)">-->
<!--          <router-link to="/uplater/up.bom.list">已推广记录</router-link>-->
<!--        </a-menu-item>-->

        <a-menu-divider/>
        <a-menu-item key="kc.gckc" v-if="allEvent.tF(23)">
          <router-link to="/stock/factory.stock">易IC工厂库存营销</router-link>
        </a-menu-item>
        <a-menu-item key="kc.spdfxx" v-if="allEvent.tF(63)">
          <router-link to="/stock/examine.order">审批营销待发信息</router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-sub-menu key="top4" v-if="allEvent.tF(3)">
        <template #title>
          <appstore-add-outlined/>
          工具
        </template>
        <a-menu-item key="gj.plgj" v-if="allEvent.tF(31)">
          <router-link to="/tools/batch">批量估价 - 新</router-link>
        </a-menu-item>
        <a-menu-divider v-if="allEvent.tF(31)"/>
<!--        <a-menu-item key="gj.jgcxa" v-if="allEvent.tF(32)">-->
<!--          <router-link to="/tools/price.a">价格查询(A)</router-link>-->
<!--        </a-menu-item>-->
        <a-menu-item key="gj.jgcxb" v-if="allEvent.tF(33)">
          <router-link to="/tools/price.b">价格查询(B)</router-link>
        </a-menu-item>
        <a-menu-item key="gj.jgcxc" v-if="allEvent.tF(34)">
          <router-link to="/tools/price.c">价格查询(C)</router-link>
        </a-menu-item>
      </a-sub-menu>


      <a-sub-menu key="datacenter" v-if="allEvent.tF(65)">
        <template #title>
          <database-outlined />
          数据
        </template>
        <a-menu-item key="datacenter.xhbjck" v-if="allEvent.tF(65)">
          <router-link to="/data.center/all.models">型号标记仓库</router-link>
        </a-menu-item>
        <a-menu-item key="datacenter.xhjgck" v-if="allEvent.tF(65)">
          <router-link to="/data.center/price.list">型号价格数据</router-link>
        </a-menu-item>

        <a-menu-divider/>

        <a-menu-item key="datacenter.zdybqsz" v-if="allEvent.tF(65)">
          <router-link to="/data.center/attribute.xx">自定义标签设置</router-link>
        </a-menu-item>


        <a-menu-item key="datacenter.xhfl" v-if="allEvent.tF(24)">
          <router-link to="/set/model.type.set">类型匹配设置</router-link>
        </a-menu-item>

        <a-menu-divider/>
        <a-menu-item key="crawlerNews" v-if="allEvent.tF(65)">
          <router-link to="/crnews/list">新闻采集</router-link>
        </a-menu-item>

      </a-sub-menu>



      <a-sub-menu key="top5" v-if="allEvent.tF(4)">
        <template #title>
          <user-switch-outlined/>
          公司
        </template>

        <a-menu-item key="top5.1.1" v-if="allEvent.tF(4)">
          <router-link to="/company/list">全部公司</router-link>
        </a-menu-item>
        <a-menu-divider v-if="allEvent.tF(4)"/>
        <a-menu-item key="top5.1.2" v-if="allEvent.tF(4)">
          <router-link to="/company/examine">审批新用户</router-link>
        </a-menu-item>
        <!--        <a-menu-item key="top5.1.3">-->
        <!--          <router-link to="/company/distribute.client">分配公司资源</router-link>-->
        <!--        </a-menu-item>-->

        <a-menu-item key="top5.2" v-if="allEvent.tF(4)">
          <router-link to="/company/label">标签</router-link>
        </a-menu-item>
      </a-sub-menu>

      <a-sub-menu key="set" v-if="allEvent.tF(5)">
        <template #title>
          <appstore-outlined/>
          设置
        </template>
        <a-menu-item key="set.qxsz" v-if="allEvent.tF(51)">
          <router-link to="/set/function.set">权限设置</router-link>
        </a-menu-item>


        <a-sub-menu key="set.qxmbsz" title="营销模板设置" v-if="allEvent.tF(53)">
          <a-menu-item key="set.qxmbsz.mail" v-if="allEvent.tF(53)">
            <router-link to="/set/template/mail">邮件模板设置</router-link>
          </a-menu-item>
          <a-menu-item key="set.qxmbsz.sms" v-if="allEvent.tF(53)">
            <router-link to="/set/template/sms">短信模板设置</router-link>
          </a-menu-item>
        </a-sub-menu>


        <a-sub-menu key="top8.1" title="基本设置" v-if="allEvent.tF(52)">
          <a-menu-item key="top8.1.1" v-if="allEvent.tF(52)">
            <router-link to="/set/suggestion">意见反馈</router-link>
          </a-menu-item>
          <a-menu-item key="top8.2.2" v-if="allEvent.tF(52)">
            <router-link to="/set/link">联系我们</router-link>
          </a-menu-item>
          <a-menu-item key="top8.3.3" v-if="allEvent.tF(52)">
            <router-link to="/set/confidentiality">保密政策</router-link>
          </a-menu-item>
          <a-menu-item key="top8.4.4" v-if="allEvent.tF(52)">
            <router-link to="/set/service">服务条款</router-link>
          </a-menu-item>
        </a-sub-menu>
        <a-menu-divider v-if="allEvent.tF(52)"/>
        <a-menu-item key="top2.2.3" v-if="allEvent.tF(5)">
          <router-link to="/order/user.need.monitor">客户关注型号</router-link>
        </a-menu-item>
<!--        <a-menu-item key="top8.2" v-if="allEvent.tF(5)">-->
<!--          <router-link to="/set/ask">报价设置</router-link>-->
<!--        </a-menu-item>-->
        <a-menu-item key="top8.3" v-if="allEvent.tF(5)">
          <router-link to="/set/news">新闻管理</router-link>
        </a-menu-item>
        <a-menu-item key="top8.4" v-if="allEvent.tF(5)">
          <router-link to="/set/friendly">友情链接</router-link>
        </a-menu-item>
        <a-menu-item key="top8.5" v-if="allEvent.tF(5)">
          <router-link to="/set/plant">平台设置</router-link>
        </a-menu-item>
      </a-sub-menu>



    </a-menu>
    <div class="icons">
      <a-popover placement="bottom">
        <template #content>打开一个新的工作窗口</template>
        <desktop-outlined class="desktop" @click="allEvent.openDesktop"/>
      </a-popover>
      <message-outlined class="message"/>
      <a-popover placement="bottom">
        <template #content>退出</template>
        <logout-outlined class="loginOut" @click="allEvent.loginOut"/>
      </a-popover>
      <label class="mySet" @click="allEvent.mySet.goOpen">
        <a-avatar>
          <template #icon>
            <UserOutlined/>
          </template>
        </a-avatar>
        <text class="userName">{{ dataValue.userName }}</text>
      </label>
    </div>
  </a-layout>

  <a-drawer
      title="个人设置"
      width="280"
      :visible="dataValue.mySet.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.mySet.goClose"
  >

    <mySet @goClose="allEvent.mySet.goClose"/>
  </a-drawer>

</template>




<script>

import {reactive, watch} from 'vue';
import router from "@/router";
import {message, Modal} from "ant-design-vue";
import store from "@/store";
import proxy from "@/utils/axios";
import mySet from "@/view/organization/my_set";

export default {
  name : "topMenu",
  props : [ 'menu_key' ],
  components : { mySet },
  setup(props) {

    let dataValue = reactive({
      menuSelectKey : "",
      functionIds : [],
      userName : "--",
      userInfo : {},
      mySet : {
        visible : false
      }
    });

    watch(() => props, (newProps) => {
      dataValue.menuSelectKey = [ newProps.menu_key ];
    }, { deep : true, immediate : true });

    let wsMessage;

    let allHttp = {

      getMyAllFunction : {

        connect : function () {
          let wsHost = process.env.VUE_APP_DAILIAO_SOCKET_URL;
          wsMessage = new WebSocket(wsHost + "/admin.socket/my.all.fun")
          wsMessage.onopen = function (evt) {
            allHttp.getMyAllFunction.open(evt);
          };
          wsMessage.onmessage = function (evt) {
            allHttp.getMyAllFunction.message(evt);
          };
          wsMessage.onclose = function (evt) {
            allHttp.getMyAllFunction.close(evt);
          };
        },

        open : function (evt) {
          // console.log( "web.socket Connection open ... " );
          wsMessage.send(store.state.jwt);
        },

        message : function (evt) {
          // console.log( "返回数据 -> "+ evt['data'] );
          store.commit("setFunction", evt['data']);
          dataValue.functionIds = JSON.parse(evt['data'])
        },

        close : function (evt) {
          // console.log( "web.socket停止，重新连接 ... " );
          setTimeout(function () {
            allHttp.getMyAllFunction.connect();
          }, 5000);
        }

      },

      getUserInfo : ()=>{

        proxy.postEIC('/human/employee/get.info', {})
            .then((res) => {
              if (res.code !== 200) {
                if( res.message.indexOf("签名") === 0 ) {
                  message.error("res.message");
                }
              } else {
                dataValue.userInfo = res.data;

                // console.log(dataValue.userInfo)

                dataValue.userName = dataValue.userInfo['employeeName'] + ( ( dataValue.userInfo['haveAdmin'] === 1 ) ? "，管理员" : "" );
              }
            })

      },

      init : () => {
        allHttp.getMyAllFunction.connect();
        allHttp.getUserInfo();
      }

    }
    allHttp.init();


    let allEvent = {

      mySet : {
        goOpen: ()=>{
          dataValue.mySet.visible = true;
        },
        goClose : ()=>{
          dataValue.mySet.visible = false;
        }
      },

      loginOut : () => {
        Modal.confirm({
          content : '请确认您要退出当前登录？',
          okText : '退出',
          cancelText : '取消',
          onOk() {
            router.push("/login");
          }
        })
      },

      tF : (...functionIds) => {
        for (let i = 0; i < dataValue.functionIds.length; i++) {
          for (let j = 0; j < functionIds.length; j++) {
            if (dataValue.functionIds[i] === functionIds[j]) return true;
          }
        }
        return false;
      },

      openDesktop : () => {

        let domainUrl = location.href.split("//")[0] + "//" + location.host;

        window.open(domainUrl, '_blank');
      }

    }


    return {
      dataValue,
      allHttp,
      allEvent
    }
  }
}
</script>

<style scoped>
.topHeader { box-shadow: 0 2px 8px #eeeeee; height: 65px; background-color: #eeeeee; border-bottom: 1px solid #cccccc;}
.topHeader .logo { position: fixed; text-align: center; width: 100px; line-height: 60px; }
.topHeader .logo img { height: 50px; border-radius: 50%; border: 1px solid #eeeeee; }
.topMenu { line-height: 43px; width: calc(100% - 250px); border-bottom: 0; margin-top: 12px; }
.topHeader .icons { position: fixed; text-align: right; width: 300px;
  right: 0; line-height: 63px; padding-right: 10px; }
.topHeader .icons .desktop { margin-right: 20px; font-size: 16px; position: relative; top: 2px; cursor: pointer; }
.topHeader .icons .desktop:hover { color: red; }
.topHeader .icons .message { margin-right: 20px; font-size: 16px; position: relative; top: 2px; }
.topHeader .icons .loginOut { margin-right: 20px; font-size: 16px; position: relative; top: 2px; cursor: pointer; }
.topHeader .icons .loginOut:hover { color: red; }
.userName{ padding: 0 3px 0 12px; position: relative; top: 2px; }
.mySet{ cursor: pointer; color: #1890ff; }
.mySet:hover{ color: #225DDC; }
</style>